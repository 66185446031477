import { listItemButtonClasses } from '@mui/material/ListItemButton';
import { listItemIconClasses } from '@mui/material/ListItemIcon';
import { listItemTextClasses } from '@mui/material/ListItemText';
import FireIcon from 'src/assets/icons/hot-event.svg?url';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  arrow: {
    transform: 'rotate(-180deg)',
    transition: theme.transitions.create(['transform']),

    '&.rotate': { transform: 'rotate(0deg)' },
  },
  list: {
    marginBottom: theme.spacing(2),
    background: theme.custom.bg[2],
    [`& .${listItemButtonClasses.root}`]: {
      padding: theme.spacing(1.5, 2),
      wordBreak: 'break-word',
      [`& .${listItemIconClasses.root}`]: {
        minWidth: 24,
      },
      [`& .${listItemTextClasses.root}`]: {
        margin: 0,
      },
      '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.custom.content[1],
      },

      '&:hover': {
        color: theme.palette.primary.main,
        [`& .isNotFav`]: {
          backgroundColor: theme.palette.custom.content[1],
          '&:hover': {
            backgroundColor: theme.palette.custom.content[1],
          },
        },
      },
    },
  },
  title: {
    cursor: 'pointer',
    background: theme.custom.bg[3],
  },
  body: {
    [`& .${listItemButtonClasses.root}`]: {
      paddingLeft: '16px',
    },
    '& > p': {
      padding: theme.spacing(1.5, 1, 1.5, 4),
    },
  },

  rect: {
    borderRadius: 3,
    width: 3,
    alignSelf: 'stretch',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },

  popular: {
    background: `url(${FireIcon.src}) no-repeat center center`,
    backgroundSize: 'contain',
    width: 14,
    height: 14,
    minWidth: 14,
    lineHeight: 0,
  },
}));
